/* Services.css */

.services-container {
    padding: 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-weight: bold;
  }
  
  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  
  .service-card {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .service-card h3 {
    margin-bottom: 10px;
    color: #007bff;
    font-weight: bold;
  }
  
  .service-card p {
    color: #666;
    line-height: 1.6;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .services-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
  
  @media screen and (max-width: 600px) {
    .services-list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  
    .service-card {
      padding: 15px;
    }
  }
  